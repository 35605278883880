import React, { useState, useEffect } from "react";
import ContentEditor from "./page_component/content_editor"
import { getRequest } from "../../serverComponents/server_contact";
import { useParams } from 'react-router-dom';


const ArticlePage = () => {
    const [editor_content, setEditorContent] = useState(null);
    const [is_article_exist, setArticleExist] = useState(null);
    const { id } = useParams();


    
    useEffect(()=>{
        const verifyAuth = async () => {
            await getRequest("/admin/auth/status/")
            .then(response => {
                if(response.status === 200){
                    window.location.href = `/pages/article_editor/${id}`;
                }
            }).catch(error => {
                if(error.request.status !== 403){
                    alert("Error connecting to the server: " + error.response.status);
                }
            });
        }
    
        const getArticle = async()=>{
            await getRequest(`/public/article/${id}/`)
            .then(response => {
                if(response.status === 200){
                    setEditorContent(response.data.article_data["blocks"]);
                    setArticleExist(true);
                }
            }).catch(error => {
                if(error.request.status !== 403 && error.request.status !== 404){
                    alert("Error connecting to the server: " + error.response.status);
                }
                setArticleExist(false)
            });
        }
    

        getArticle();
        verifyAuth();
    }, [id])

    return (
        <div className="container-fluid ">
            <div className="row px-3 xs:p-0 leading-relaxed">
                <div className="cold-md-12">
                    {
                        editor_content && <ContentEditor onchange_callback={setEditorContent} article_block={editor_content} read_only={true} />
                    }
                    {
                        is_article_exist === false 
                        && 
                        <div className="d-flex justify-content-center align-items-center color-black page">
                            <p className="font-bold font-titles text-3xl">No Article Found</p>
                        </div>
                    }
                    {
                        is_article_exist === null 
                        && 
                        <div className="d-flex justify-content-center align-items-center color-black page">
                            <p className="font-bold font-titles text-3xl">Loading...</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}


export default ArticlePage;