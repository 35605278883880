import React from 'react'
import { Skeleton } from '@chakra-ui/react'


const GalleryPostCardSkeleton = () => {
    return ( 
        <div className="col-sm-3 mt-2">
            <div className="pl-1 pr-1 pt-1">
                <Skeleton height='298px' />
            </div>
        </div>
     );
}
 
export default GalleryPostCardSkeleton;