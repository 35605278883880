import React, { useEffect, useState } from "react";
import TitleHeader from '../templates/title_header';
import AppBanner from "../templates/app_banner";
import { getRequest } from "../../serverComponents/server_contact";
import AppBannerSkeleton from "../templates/app_banner_skeleton";
import { useToast } from '@chakra-ui/react';


const AppsPage = () => {
    const [apps, setApps] = useState(null);
    const toast = useToast();

    useEffect(()=>{
        const getApps = async()=>{
            await getRequest('/applications/public/')
            .then(response=>{
                if(response.status === 200){
                    setApps(response.data);
                }
            }).catch(error=>{
                toast({
                    title: 'API Warning',
                    description: "Interuption Error: A reload usually fix this error: " + error,
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                });
            });
        }

        getApps();
    }, [toast]);

    return(
        <div className="container">
            <div className="row mt-5">
                <div className="col-md-12">
                    <TitleHeader title={"Applications"} subtitle={"My Applications"} />
                </div>
            </div>

            <div className="row mt-5">
                {
                    apps === null
                    ? 
                    <>
                        <div className="col-sm-4 mt-4s">
                            <AppBannerSkeleton /> 
                        </div>
                        <div className="col-sm-4 mt-4s">
                            <AppBannerSkeleton /> 
                        </div>
                        <div className="col-sm-4 mt-4s">
                            <AppBannerSkeleton /> 
                        </div>
                    </>
                    :
                    apps.map((app, index) => {
                        return(
                            <div className="col-sm-4 mt-4s" key={index}>
                                <AppBanner app_name={app.app_name} app_icon={app.app_icon} view_link={app.app_url} platforms={app.platforms}/>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}


export default AppsPage;