import React, { useState, useEffect } from "react";
import { getRequest } from "../../serverComponents/server_contact";
import { getArticleSummary } from "../utils";
import TitleHeader from '../templates/title_header';
import ArticleHighlightCard from '../templates/article_highlight_card';
import ArticleHighlightCardSkeleton from "../templates/article_highlight_card_skeleton";



const Articles = ()=>{
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [articles, setArticles] = useState(null);


    useEffect(()=>{
        window.scrollTo(0, 0);
        const getAllArticles = async()=>{
            let url_path = "/public/article/?is_posted=true";
            if(isAuthenticated){
                url_path = "/public/article/";
            }
            
            await getRequest(url_path)
            .then(response => {
                if(response.status === 200){
                    setArticles(response.data);
                } 
            })
            .catch(error => {
                if(error.response.status !== 403){
                    alert("Error retrieving article data" + error);
                    getAllArticles();
                }
            })
        }

        const verifyAuth = async () => {
            await getRequest("/admin/auth/status/")
            .then(response => {
                if(response.status === 200){
                    setIsAuthenticated(true);
                }

                getAllArticles();
            }).catch(error => {
                if(error.request.status === 403){
                    setIsAuthenticated(false);
                    getAllArticles();
                } else {
                    alert("Error connecting to the server: " + error.response.status);
                }
            });
        }
        
       

        verifyAuth();
        
        console.log("Articles page loaded")
    }, [setIsAuthenticated, isAuthenticated]);



    return (
        <div className="container">
            
            {
                isAuthenticated
                &&
                <div className="row">
                    <div className="col-md-12 d-flex alignt-items-end justify-content-end">
                        <button className="primary-button mt-5" onClick={()=>{window.location.href = "/pages/article_editor/"}}>Create New Article</button>
                    </div>
                </div>
            }
           
            <div className="row mt-5">
                <div className="col-md-12">
                    <TitleHeader title={"Articles"} subtitle={"Article about computer programming, game developments, and more"} />
                </div>
            </div>

            <div className="row mt-5 px-3 xs:p-0">
                
                { (articles && Object.keys(articles).length >= 1)? 
                    Object.keys(articles).map(article_key => {
                        let summary = getArticleSummary(articles[article_key].article_data.blocks)
                        return <div className="col-sm-4 mt-4">
                            <ArticleHighlightCard title={summary.heading} body={summary.parapgraph} image={summary.cover_image} article_id={articles[article_key].id} post_status={articles[article_key].is_posted} />
                        </div>
                    })
                    :
                    (articles && Object.keys(articles).length === 0 )
                    ? 
                    <p className="font-bold text-2xl text-center">No articles yet</p>
                    :
                    <>
                        <div className="col-sm-4 mt-4">
                            <ArticleHighlightCardSkeleton />
                        </div>
                        <div className="col-sm-4 mt-4">
                            <ArticleHighlightCardSkeleton />
                        </div>
                        <div className="col-sm-4 mt-4">
                            <ArticleHighlightCardSkeleton />
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

export default Articles;