import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from '@chakra-ui/react'

import AndroidIcon from "../../inpage_images/icons/android.svg";
import WindowsIcon from "../../inpage_images/icons/windows.svg";
import LinuxIcon from "../../inpage_images/icons/linux.svg";
import WebIcon from "../../inpage_images/icons/web.svg";
import IosIcon from "../../inpage_images/icons/ios.svg";

const AppBanner = ({app_name, app_icon, view_link, platforms=[]}) => {
    return(
        <div className='rounded-lg bg-gray-100 w-100 p-4'>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <div className="bg-gray-500 w-[50px] h-[50px]" >
                        {
                            app_icon && <img src={app_icon} alt={app_name} className='w-full h-full object-cover'/>
                        }
                    </div>
                    <p className='text-1xl pl-5 font-bold'>{app_name}</p>
                </div>

                <Link to={view_link} className="primary-button mx-2" aria-current="page">View Application</Link>
            </div>
            <p className="text-gray-400 text-sm mt-3">Platforms:</p>
            <div className="d-flex align-items-center">
                {
                    platforms.includes("android")
                    &&
                    <Tooltip label="Android Platform" aria-label='A tooltip'>
                        <img src={AndroidIcon} alt="Android" className="w-8 h-8 mx-1"/>
                    </Tooltip>
                }

                {
                    platforms.includes("windows")
                    &&
                    <Tooltip label="Windows Platform" aria-label='A tooltip'>
                        <img src={WindowsIcon} alt="Windows" className="w-8 h-8 mx-1"/>
                    </Tooltip>
                }
                
                {
                    platforms.includes("linux")
                    &&
                    <Tooltip label="Linux Platform" aria-label='A tooltip'>
                        <img src={LinuxIcon} alt="Linux" className="w-8 h-8 mx-1"/>
                    </Tooltip>
                }
                
                {
                    platforms.includes("web")
                    &&
                    <Tooltip label="Web Platform" aria-label='A tooltip'>
                        <img src={WebIcon} alt="Web" className="w-8 h-8 mx-1"/>
                    </Tooltip>
                }
                
                {
                    platforms.includes("ios")
                    &&
                    <Tooltip label="IOS Platform" aria-label='A tooltip'>
                        <img src={IosIcon} alt="Ios" className="w-8 h-8 mx-1"/>
                    </Tooltip>
                }
            </div>
        </div>
    );
}


export default AppBanner;