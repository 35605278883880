const getArticleSummary = (article_block)=>{
    let summary_data = {
        heading : "",
        parapgraph : "",
        cover_image : "",
    }

    Object.keys(article_block).map(block_keys => {
        let mapped_block = article_block[block_keys];

        if(mapped_block.type === "header" && summary_data.heading === ""){
            summary_data.heading = mapped_block.data.text;
        }

        if(mapped_block.type === "paragraph" && summary_data.parapgraph === ""){
            if (mapped_block.data.text.length >= 100){
                summary_data.parapgraph = mapped_block.data.text.slice(0, 200); 
            }
            
        }

        if(mapped_block.type === "image" && summary_data.cover_image === ""){
            summary_data.cover_image = mapped_block.data.file.url;
        }       

        return null;
    });

    return summary_data;
}


export { getArticleSummary }