import { getRequest, postRequest, deleteRequest } from '../../serverComponents/server_contact';
import React, { useState, useEffect } from 'react';
import './gallery.css';
import GalleryPostCard from "../subComponents/gallery_post_card";
import MaintenancePage from '../subComponents/maintenance_page';
import TitleHeader from '../templates/title_header';
import GalleryPostCardSkeleton from '../templates/gallery_post_card_skeleton';


const CheckMaintenance = (connection_connected)=>{
    if (!connection_connected){
        return <MaintenancePage />
    }
}




const Gallery = () => {
    const [connection_stablished, setConnectionStablished] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [image_list, setimageList] = useState();
    const [image_url, setImageUrl] = useState();
    

    const onImageInputChange = (e) => {
        setImageUrl(e.target.files[0]);
    };


    const verifyAuth = async () => {
        await getRequest("/admin/auth/status/")
        .then(response => {
            if(response.status === 200){
                setIsAuthenticated(true);
            }
        }).catch(error => {
            if(error.response.status === 403){
                setIsAuthenticated(false);
            } else{
                alert("Error connecting to the server: " + error.response.status);
            }
        });
    }


    const deleteImage = async(id)=>{
        await deleteRequest(`/auth/gallery/${id}/`, true)
        .then(response => {
            if(response.status === 204){
                alert("Image was deleted");
                loadGallery();
            }
        })
        .catch(error => {
            alert("Error deleting image");
        });
    }


    const UploadImage = async()=>{
        const data = {
            image_title: document.getElementById("new_title").value,
            image_description: document.getElementById("new_description").value,
            is_highlight : document.getElementById("is_highlight").value,
            image_url: image_url
        }
        await postRequest("/auth/gallery/", data, true)
        .then(response => {
            if(response.status === 201){
                alert("Image uploaded successfully");
                loadGallery();
            }
        })
        .catch(error => {
            alert("Error uploading image");
        });
    }


    const loadGallery = async()=>{
        await getRequest("/public/gallery/")
        .then(responseData => {
          setimageList(responseData.data);
          setConnectionStablished(true)
        })
        .catch(error => {
          setConnectionStablished(false)
        });
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        verifyAuth();
        loadGallery();
      }, []);



    return ( 
        <div className="gallery container">
            <div className="row">
                <div className="col-md-12 font-titles">
                    <TitleHeader title={"Gallery"} subtitle={"Images rendered from blender software"} />
                </div>
            </div>

            {
                isAuthenticated 
                &&
                <div className='row mt-2 mb-2'>
                    <div className='col-md-12'>
                        <p className='text-3xl'>Upload Image</p>

                        <p className='form-label mt-3'>Title</p>
                        <input type='text' className='form-control' id="new_title" />

                        <p className='form-label'>Description</p>
                        <textarea type='text' className='form-control' rows="7" id="new_description" />

                        <p className="form-label text-xs">Highlighted</p>
                        <select className="form-select" aria-label="article meta" id="is_highlight">
                            <option value={false} defaultValue>Normal Image</option>
                            <option value={true}>Highlight Image</option>
                        </select>

                        <input accept="image/jpeg,image/png,image/gif" className="form-control mt-2" type="file" id="new_image" onChange={(e) => {onImageInputChange(e)}}/>
                        
                        <button className="primary-button w-100 mt-3" onClick={UploadImage}>Upload image</button>
                    </div>
                </div>
            }
            


            

            <div className="row mt-2">
                        
                    {CheckMaintenance(connection_stablished)}
                    {
                         image_list 
                         ?
                            Object.keys(image_list).map((key) => (
                                <div className="col-sm-3 mt-2">
                                    <div className="pl-1 pr-1 pt-1">
                                        <GalleryPostCard gallery_post_data = {image_list[key]} is_authenticated={isAuthenticated} delete_callback={deleteImage}/>  
                                    </div>
                                </div>
                            ))
                        :
                        <>
                            <GalleryPostCardSkeleton />
                            <GalleryPostCardSkeleton />
                            <GalleryPostCardSkeleton />
                            <GalleryPostCardSkeleton />
                        </> 
                    }
            </div>
        </div>
     );
}
 
export default Gallery;