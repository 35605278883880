import React from "react";
import { Skeleton } from '@chakra-ui/react'

const AppBannerSkeleton = () => {
    return(
        <div className='rounded-lg w-100 p-4'>
           <Skeleton height='182px'/>
        </div>
    );
}


export default AppBannerSkeleton;