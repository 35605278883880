import React, {useEffect, useState} from "react";
import { getRequest, postRequest, patchRequest, deleteRequest, authVerify} from "../../serverComponents/server_contact";
import ContentEditor from "./page_component/content_editor"
import { useParams } from 'react-router-dom';




const ArticleEditor = () => {
    const [editor_content, setEditorContent] = useState(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { id } = useParams();

    const verifyAuth = async () => {
        await authVerify(setIsAuthenticated)
    }
    
    const updateArticle = async () => {
        const data = {
            article_data : JSON.stringify(editor_content),
            article_meta_msg : document.getElementById("article_meta").value,
            is_posted : document.getElementById("is_posted").value,
            is_highlight : document.getElementById("is_highlight").value
        }
        console.log(data);
        
        await patchRequest(`/admin/article/${id}/`, data, true)
        .then(response => {
            if(response.status === 200){
                alert("Article updated successfully");
            }
        }).catch(error => {
            alert("Error updating article");
        });
    }


    const saveArticle = async () => {
        const data = {
            article_data : JSON.stringify(editor_content),
            article_meta_msg : document.getElementById("article_meta").value,
            is_posted : document.getElementById("is_posted").value,
            is_highlight : document.getElementById("is_highlight").value
        }
        console.log(data);
        await postRequest("/admin/article/", data, true)
        .then(response => {
            if(response.status === 201){
                alert("Article saved successfully");
            }
        }).catch(error => {
            alert("Error saving article");
        });
    }


    const deleteArticle = async()=>{
        await deleteRequest(`/admin/article/${id}/`, true)
        .then(response => {
            if(response.status === 204){
                window.location.href = '/pages/articles';
            }
        }).catch(error => {
            alert("Error deleting article");
        });
    }


    

    
    useEffect(()=>{
        verifyAuth();

        const GetArticle = async()=>{
            await getRequest(`/public/article/${id}/`)
            .then(response => {
                if(response.status === 200){
                    setEditorContent(response.data.article_data["blocks"]);
                    document.getElementById("article_meta").value = response.data.article_meta_msg;
                    document.getElementById("is_posted").value = response.data.is_posted;
                    document.getElementById("is_highlight").value = response.data.is_highlight;
                }
            }).catch(error => {
                if(error.request.status !== 403 && error.request.status !== 404){
                    alert(`Error getting article ${id} with status code: ` + error.request.status);
                }
                
            });
        }

        if(id !== undefined){
            GetArticle();
        }
    }, [id]);
    


    return(
        <div className="container-fluid">
            <div className="row mt-5">
                <div className="col-md-9 ">
                    {
                        isAuthenticated === false
                        &&
                        <div className="page d-flex flex-column align-items-center justify-content-center">
                            <p className="font-fold text-4xl color-primary">Are you Mark?...</p>
                            <span className="text-xs">This is a recorded access attempt!</span>
                        </div>
                    }
                    {   
                        id ? 
                        editor_content 
                        &&
                        <ContentEditor onchange_callback={setEditorContent} article_block={editor_content} read_only={false}/>
                        :
                        <ContentEditor onchange_callback={setEditorContent} read_only={false} />
                    }
                </div>

                <div className="col-md-3 border-start">
                    <p className="form-label text-xs">Article Meta</p>
                    <input type="text" className="form-control" placeholder="Meta" id="article_meta"/>
                    <br />
                    <p className="form-label text-xs">Post status</p>
                    <select className="form-select" aria-label="article meta" id="is_posted">
                        <option value={true}>Posted</option>
                        <option value={false} defaultValue>Draft</option>
                    </select>

                    <p className="form-label text-xs">Highlighted</p>
                    <select className="form-select" aria-label="article meta" id="is_highlight">
                        <option value={true}>Highlight Article</option>
                        <option value={false} defaultValue>Normal Article</option>
                    </select>

                    {
                        (id === undefined || id === null)
                        &&
                        <button className="primary-button w-100 mt-3" onClick={saveArticle}>Save Article</button>
                    }
                    
                    {id !== undefined && <button className="primary-button w-100 mt-3" onClick={updateArticle}>Update</button>}
                    {id !== undefined && <button className="primary-button w-100 mt-3" onClick={deleteArticle}>Delete</button>}
                </div>
            </div>
        </div>
    );
}


export default ArticleEditor;