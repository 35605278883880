import React from "react";
import IconMaintenance from "./icon_maintenance";
import './maintenance_page.css';

const MaintenancePage = () => {
    return ( 
        <div className="MaintenancePage box-shadow">
            <div className="row">
                <div className="col-md-6 d-flex justify-content-center ">
                    <IconMaintenance id='maintenance_icon'/>
                </div>
                <div className="col-md-6 d-flex justify-content-center align-items-center">
                    <p id='maintenance_text'>Ongoing Server Maintenance</p>
                </div>
            </div>
        </div>
     );
}
 
export default MaintenancePage;