import React, { useState, useEffect } from 'react';
import './homepage.css';
import PageIllustration from '../inpage_images/homepage_illustration.svg';
import { useToast } from '@chakra-ui/react';

import VenoBox from 'venobox';
import { Link } from "react-router-dom";
import { getRequest } from '../serverComponents/server_contact';
import { getArticleSummary } from "./utils";
import GalleryPostCard from "./subComponents/gallery_post_card";
import ArticleHighlightCard from './templates/article_highlight_card';


import PythonUIcon from '../inpage_images/icons/python_icon.svg';
import HTMLIcon from '../inpage_images/icons/html_icon.svg';
import JSIcon from '../inpage_images/icons/javacscript_icon.svg';
import CSSIcon from '../inpage_images/icons/css_icon.svg';
import PHPIcon from '../inpage_images/icons/php_icon.svg';
import GodotIcon from '../inpage_images/icons/godot_icon.svg';
import BlenderIcon from '../inpage_images/icons/blender_icon.svg';
import ArticleHighlightCardSkeleton from "./templates/article_highlight_card_skeleton";
import GalleryPostCardSkeleton from './templates/gallery_post_card_skeleton';

import ReactJSIcon from '../inpage_images/icons/reactjs.svg';
import ReactNativeIcon from '../inpage_images/icons/react-native.svg';
import DJangoRFIcon from '../inpage_images/icons/django-rf.svg';
import DjangoIcon from '../inpage_images/icons/django.svg';
import LaravelIcon from '../inpage_images/icons/laravel.svg';
import TailwindIcon from '../inpage_images/icons/tailwind.svg';
import Bootstrap from '../inpage_images/icons/bootstrap.svg';
import ScikitLearnIcon from '../inpage_images/icons/scikitlearn.svg';
import NumpyIcon from '../inpage_images/icons/numpy.svg';
import MatplotlibIcon from '../inpage_images/icons/matplotlib.svg';
import AxiosIcon from '../inpage_images/icons/axios.svg';
import PandasIcon from '../inpage_images/icons/pandas.svg';
import GithubIcon from '../inpage_images/icons/github.svg';
import LinkedInIcon from '../inpage_images/icons/linkedin.svg';



// Custom Templates
import SkillCardTemplate from './templates/skill_card_template';
import TitleHeader from './templates/title_header';






const Home = () => {
    const [highlight_articles, setHightlightArticles] = useState(null);
    const [highlight_image, setHighlightImage] = useState(null);
    const toast = useToast();

    const skills = {
        python: {
            title: 'Python',
            description: 'Skilled in Python for web development, data science, and AI, with experience in Django, Django Rest Framework, Pandas, and Scikit-learn.'
        },
        html: {
            title: 'HTML',
            description: 'Skilled in HTML, the standard markup language for web documents, with knowledge of CSS and JavaScript.'
        },
        javascript: {
            title: 'JavaScript',
            description: 'Skilled in JavaScript for creating interactive web pages and applications, widely supported by modern web browsers.'
        },
        css: {
            title: 'CSS',
            description: 'Skilled in CSS for controlling the look and formatting of HTML documents.'
        },
        php: {
            title: 'PHP',
            description: 'Skilled in PHP for server-side scripting and web development, compatible with various databases.'
        },
        godot: {
            title: 'Godot Game Engine',
            description: 'Skilled in Godot, an open-source game engine for creating games and interactive applications.'
        },
        blender: {
            title: 'Blender',
            description: 'Skilled in Blender, a 3D computer graphics software for creating animated films, visual effects, and more.'
        }
    }


    


    


    useEffect(()=>{
        new VenoBox({
            selector: '.my-image-links',
            numeration: true,
            infinigall: true,
            share: true,
            spinner: 'rotating-plane'
        });

        
        const getHighlightArticle = async()=>{
            let url_path = "/public/article/?is_highlight=true&is_posted=true";
            await getRequest(url_path)
            .then(response => {
                if(response.status === 200){
                    setHightlightArticles(response.data);
                }
            })
            .catch(error => {
                toast({
                    title: 'API Warning',
                    description: "Interuption Error: A reload usually fix this error: " + error,
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                });
            })
        }


        const loadGallery = async()=>{
            let url_path = "/public/gallery/?is_highlight=true";
            await getRequest(url_path)
            .then(responseData => {
                setHighlightImage(responseData.data);
            })
            .catch(error => {
                toast({
                    title: 'API Warning',
                    description: "Interuption Error: A reload usually fix this error: " + error,
                    status: 'warning',
                    duration: 9000,
                    isClosable: true,
                });
            });
        }

        loadGallery();
        getHighlightArticle();
        
        window.scrollTo(0, 0);
    }, [toast]);

    return ( 
        <div className="home">
            <div className="container">
                <div className="row h-[90vh]">
                    <div className="col-md-6 px-5 d-flex align-items-center justify-content-center">
                        <div className="">
                            <span className='text-3xl' >my name is</span> <br/>
                            <span className='font-bold md:text-6xl text-5xl md-pt-5 color-primary'>Mark John</span><br/>
                            <p id="intro_sub_3" className='font-primary mb-3 pt-3 leading-relaxed'>
                                An Astronomer, Artist, Application Developer, Web Developer, and a Game Developer. Most importantly, I play guitar.
                            </p>

                            <div className='d-flex flex-row align-items-center justify-content-between'>
                                <Link to="/pages/contact" className="primary-button mr-3" aria-current="page">Contact Me</Link>
                                <div className='d-flex mb-4 mt-4'>
                                    <img src={ GithubIcon } alt="github" className="w-8 h-8 mr-2 cursor-pointer" onClick={()=>(window.location.href = 'https://github.com/callmemark')}/>
                                    <img src={ LinkedInIcon } alt="linkedin" className="w-8 h-8 cursor-pointer" onClick={()=>(window.location.href='https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile')}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-block d-md-block d-flex d-md-flex align-items-center justify-content-center">
                        <img src={PageIllustration} alt="illustration" className=" w-[30rem]"/>
                    </div>
                </div>
                
                
                <div className='row mt-5 pt-5'>
                    <div className='col-12'>
                        <div id="tech_skill_header_cont">
                            <TitleHeader title={"Technical Skills"} subtitle={"Skills Ive gain over the years"} />
                        </div>

                        <div className='row mt-2'>
                            <div className='col-md-6 p-3'>
                                <SkillCardTemplate 
                                    skill_name={skills.python.title} 
                                    skill_description={skills.python.description} 
                                    skill_image={PythonUIcon} 
                                    badge_item={
                                    [
                                        [DjangoIcon, "Django Web Framework"], 
                                        [DJangoRFIcon, "Django Rest Framework"], 
                                        [ScikitLearnIcon, "Scikit Learn Library"], 
                                        [NumpyIcon, "Numpy Library"], 
                                        [MatplotlibIcon, "Matplotlib Library"], 
                                        [PandasIcon, "Pandas Library"]
                                    ]
                                }/>
                            </div>

                            <div className='col-md-6 p-3'>
                                <SkillCardTemplate 
                                    skill_name={skills.javascript.title} 
                                    skill_description={skills.javascript.description} 
                                    skill_image={JSIcon} 
                                    badge_item={
                                        [
                                            [ReactJSIcon, "ReactJS Library"], 
                                            [ReactNativeIcon, "React Native Framework"], 
                                            [AxiosIcon, "Axios Library"]
                                        ]
                                    }/>
                            </div>

                            <div className='col-md-6 p-3'>
                                <SkillCardTemplate skill_name={skills.html.title} skill_description={skills.html.description} skill_image={HTMLIcon}/>
                            </div>

                            <div className='col-md-6 p-3'>
                                <SkillCardTemplate skill_name={skills.css.title} skill_description={skills.css.description} skill_image={CSSIcon} badge_item={
                                    [
                                        [TailwindIcon, "Tailwind Library"], 
                                        [Bootstrap, "Bootstrap Library"]
                                    ]
                                }/>
                            </div>

                            <div className='col-md-6 p-3'>
                                <SkillCardTemplate skill_name={skills.php.title} skill_description={skills.php.description} skill_image={PHPIcon} badge_item={
                                    [
                                        [LaravelIcon, "Laravel Framework"]
                                    ]
                                }/>
                            </div>

                            <div className='col-md-6 p-3'>
                                <SkillCardTemplate skill_name={skills.godot.title} skill_description={skills.godot.description} skill_image={GodotIcon}/>
                            </div>

                            <div className='col-md-6 p-3'>
                                <SkillCardTemplate skill_name={skills.blender.title} skill_description={skills.blender.description} skill_image={BlenderIcon}/>
                            </div>
                        </div>
                    </div>
                </div>



                <div className='row my-40 px-3 xs:p-0' id="section-3">
                    <div className='col-12'>
                        <TitleHeader title={"Highlight Article"} subtitle={"Must read articles"} />
                        
                        <div className='row mt-5'>
                            { 
                                highlight_articles 
                                ? 
                                    Object.keys(highlight_articles).map(article_key => {
                                        let summary = getArticleSummary(highlight_articles[article_key].article_data.blocks)
                                        return <div className="col-sm-4 mt-4">
                                            <ArticleHighlightCard title={summary.heading} body={summary.parapgraph} image={summary.cover_image} article_id={highlight_articles[article_key].id}/>
                                        </div>
                                    })
                                :
                                    (highlight_articles && Object.keys(highlight_articles).length === 0)
                                ?
                                    <p className="font-bold text-2xl text-center color-black ">No articles yet</p>
                                :
                                    <>
                                        <div className="col-sm-4 mt-4">
                                            <ArticleHighlightCardSkeleton />
                                        </div>
                                        <div className="col-sm-4 mt-4">
                                            <ArticleHighlightCardSkeleton />
                                        </div>
                                        <div className="col-sm-4 mt-4">
                                            <ArticleHighlightCardSkeleton />
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
                

                <div className='row m-20 bg-gray-100 rounded-lg' id="section-4">
                    <div className='col-md-12 pt-5 pb-5'>
                        <TitleHeader title={"Gallery"} subtitle={"Current Best renders"} />

                        <div className='row mt-5'>
                            {
                                highlight_image 
                                ?
                                Object.keys(highlight_image).map((key) => (
                                    <div className="col-sm-3 mt-2">
                                        <div className="pl-1 pr-1 pt-1">
                                            <GalleryPostCard gallery_post_data = {highlight_image[key]} is_authenticated={false} delete_callback={null}/>  
                                        </div>
                                    </div>
                                ))
                                :
                                <>
                                    <GalleryPostCardSkeleton />
                                    <GalleryPostCardSkeleton />
                                    <GalleryPostCardSkeleton />
                                    <GalleryPostCardSkeleton />
                                </> 
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}
 
export default Home;
