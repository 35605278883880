import React from 'react';
import { Link, useLocation } from "react-router-dom";
import './navbar.css';
import Dropdown from 'react-bootstrap/Dropdown';
import MainLogoDark from "../inpage_images/icons/main_logo_dark.svg";
//import MainLogo from "../inpage_images/icons/main_logo.svg";

const Navigationbar = () => {
  const location = useLocation();

  return ( 
    <nav className="navbar sticky-top px-md-5 navbar-expand-md navigation_bar">
        <div className='col-4 align-items-center d-flex'>
          <Link to="/" aria-current="page">
            <img src={ MainLogoDark } alt='my logo' className='pl-5' id="header_main_logo"></img>
          </Link>
        </div>
      
        <div className='col-8 d-flex justify-content-end'>
          <ul className="navbar-nav">
            <li className={`nav-item ${location.pathname === '/' ? 'active' : ''} d-none d-md-block d-lg-block`}>
              <Link to="/" className="nav-link" aria-current="page">
              <span className={`navigation_name font-bold text-sm ${location.pathname === '/' ? 'color-primary' : 'color-black'}`}>Home</span></Link>
            </li>

            <li className={`nav-item ${location.pathname === '/pages/contact' ? 'active' : ''} d-none d-md-block d-lg-block`}>
              <Link to="/pages/contact" className="nav-link">
              <span className={`navigation_name font-bold text-sm ${location.pathname === '/pages/contact' ? 'color-primary' : 'color-black'}`}>Contact</span></Link>
            </li>

            <li className={`nav-item ${location.pathname === '/pages/gallery' ? 'active' : ''} d-none d-md-block d-lg-block`}>
              <Link to="/pages/gallery" className="nav-link">
              <span className={`navigation_name font-bold  text-sm ${location.pathname === '/pages/gallery' ? 'color-primary' : 'color-black'}`}>Gallery</span></Link>
            </li>

            <li className={`nav-item ${location.pathname === '/pages/articles' ? 'active' : ''} d-none d-md-block d-lg-block`}>
              <Link to="/pages/articles" className="nav-link">
                <span className={`navigation_name font-bold text-sm ${location.pathname === '/pages/articles' ? 'color-primary' : 'color-black'}`}>Articles</span>
              </Link>
            </li>

            <li className={`nav-item ${location.pathname === '/pages/apps' ? 'active' : ''} d-none d-md-block d-lg-block`}>
              <Link to="/pages/apps" className="nav-link">
                <span className={`navigation_name font-bold text-sm ${location.pathname === '/pages/apps' ? 'color-primary' : 'color-black'}`}>Apps</span>
              </Link>
            </li>

            <li className='d-block d-sm-block d-md-none'>
              <Dropdown>
                <Dropdown.Toggle variant="dark" id="dropdown-basic" className='mx-2'>
                  Menu
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Link to="/" className={`nav-link ${location.pathname === '/' ? 'active' : ''}`} aria-current="page">Home</Link>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Link to="/pages/gallery" className={`nav-link ${location.pathname === '/pages/gallery' ? 'active' : ''}`}>Gallery</Link>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Link to="/pages/contact" className={`nav-link ${location.pathname === '/pages/contact' ? 'active' : ''}`}>Contact</Link>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Link to="/pages/articles" className={`nav-link ${location.pathname === '/pages/articles' ? 'active' : ''}`}>Articles</Link>
                  </Dropdown.Item>

                  <Dropdown.Item>
                    <Link to="/pages/apps" className={`nav-link ${location.pathname === '/pages/apps' ? 'active' : ''}`}>Apps</Link>
                  </Dropdown.Item>

                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </div>
    </nav>
  );
}

export default Navigationbar;
