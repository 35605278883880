import React, { useEffect, useState } from 'react';
import Footer from "./footer";
import Navigationbar from "./navbar";
import { Outlet } from "react-router-dom";
import { getRequest } from '../serverComponents/server_contact';
import '../App.css';

const RootLayout = () => {
    const [settings, setSettings] = useState(null);


    useEffect(()=>{
        const getSettings = async()=>{
            await getRequest("/admin/auth/settings/")
            .then(response => {
                if(response.status === 200){
                    setSettings(response.data[0]);
                }
            })
            .catch(error=>{
                alert("Error getting settings from the server: " + error.request.status);
            })
        }

        getSettings();
    }, [])

    return ( 
        <div className="root_layout">
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM" crossOrigin="anonymous"></link>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-geWF76RCwLtnZ8qwWowPQNguL3RmwHVBC9FhGdlKrxdiJJigb/j/68SIy3Te4Bkz" crossOrigin="anonymous"></script>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,200,0,0" />
            <Navigationbar />
            {
                settings !== null
                &&
                settings.show_note === true
                &&
                <div className='row mt-5'>
                    <div className='col-md-12'>
                        <div className='w-100 bg-red-100 pt-2 absolute top-12'>
                            <p className='font-bold text-md text-rose-700'>{settings && settings.note_title}</p>
                            <p>{settings && settings.note_message}</p>
                        </div>
                    </div>
                </div>
            }
            

            <Outlet />
            <Footer />
        </div>
    );
}

export default RootLayout
