import React from "react";
import { Skeleton, SkeletonText } from '@chakra-ui/react'


const ArticleHighlightCardSkeleton = () => {
    return(
        <div className='rounded-lg'>
            <Skeleton height='224px' />
           
            <div id="spotlight_card_content" className='max-h-full w-100 pt-4'>
                <SkeletonText mt='1' noOfLines={1} spacing='1' skeletonHeight='2' />
                
                <SkeletonText mt='5' noOfLines={5} spacing='4' skeletonHeight='2' className='mt-3 p-2'/>
            </div>

            <div className='d-flex justify-content-end align-items-end max-h-full w-100'>
            </div>
        </div>
    );
}

export default ArticleHighlightCardSkeleton;