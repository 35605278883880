import React, { useState, useEffect } from "react";
import { postRequest, getRequest } from "../../serverComponents/server_contact";





const LoginPage = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [auth_status, setAuthStatus] = useState("No status");

    const handleLogin = async () => {
        setAuthStatus("Logging in...");
        await postRequest("/admin/auth/login/", {email, password}, true)
        .then(response => {
            if(response.status === 200){
                setAuthStatus("Authenticated");
                sessionStorage.setItem("acctoken", response.data.token);
            }
        }).catch(error => {
            if(error.response.status === 403){
                setAuthStatus(error.response.data.response);
            }
        });
    };


    const handleLogOut = async () => {
        await postRequest("/admin/auth/logout/", {}, true)
        .then(response => {
            if(response.status === 200){
                sessionStorage.clear();
                setAuthStatus("Logged out successfully");
            }
        }).catch(error => {
            setAuthStatus(`Error: ${error.response.data.response}`);
        })
    }


    const handleTest = async()=>{
        await getRequest("/admin/auth/status/")
        .then(response => {
            if(response.status === 200){
                setAuthStatus("Authenticated");
            }else{
                setAuthStatus("Unfavorable status: " + response.status);
            }
        }).catch(error => {
            setAuthStatus("Authentication Failed");
        });
    }

    useEffect(() => {
        handleTest();
    }, [])


    return (
        <div className="container-fluid">
            <div className="row mt-5 mb-5">
                <div className="col-12 d-flex align-items-center justify-content-center">
                    <div className="w-full sm:w-1/2 box-shadowed d-flex flex-column align-items-center justify-content-center p-5 my-5">
                        <h1>Login</h1>
                        <br/>
                        <input className="form-control" type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="email" />
                        <br/>
                        <input className="form-control" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
                        <br/>
                        <button className="primary-button w-100" onClick={handleLogin}>Login</button>
                        
                        <br />
                        <button className="primary-button w-100" onClick={handleLogOut}>Logout</button>

                        <br/>
                        <button className="primary-button w-100" onClick={handleTest}>Auth Test</button>
                        
                        {auth_status && <p className="mt-5">{auth_status}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;