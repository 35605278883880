import React from "react";
import { Link } from "react-router-dom";



const ArticleHighlightCard = ({title, body, image, article_id, post_status=true}) => {
    return(
        <div className='rounded-lg'>
            <div className="w-100 min-h-56 max-h-56 overflow-hidden d-flex align-items-center justify-content-center rounded-lg bg-gray-100 px-4" >
                <img src={ image } alt="test_image" className="w-100"/>
            </div>
           
            <div id="spotlight_card_content" className='max-h-full w-100 pt-4'>
                <div className="h-16">
                    <p className='text-1xl  border-s-2 border-color-primary font-bold mt-3 color-black pl-5'>{ title }
                    {post_status === false && <span className="text-sm font-bold color-primary"> : Draft</span>}
                    </p>
                </div>
                
                <p className='font-primary mt-3 p-2 text-sm color-black leading-relaxed'>{ body }</p>
            </div>

            <div className='d-flex justify-content-end align-items-end max-h-full w-100'>
                <Link to={`/pages/article/${article_id}`} className="primary-button rounded-lg">Read More</Link>
            </div>
        </div>
    );
}

export default ArticleHighlightCard;