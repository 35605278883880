import React, { useRef, useEffect } from "react";
import EditorJS from '@editorjs/editorjs';



import Header from '@editorjs/header';
import ImageTool from '@editorjs/image';
import Delimiter from '@editorjs/delimiter';
import List from '@editorjs/list';
import Checklist from '@editorjs/checklist';
import Quote from '@editorjs/quote';
import CodeTool from '@editorjs/code';
//import Embed  from '@editorjs/embed';
import Table from '@editorjs/table';
import LinkTool from '@editorjs/link';
import Warning from '@editorjs/warning';
import Marker from '@editorjs/marker';
import InlineCode from '@editorjs/inline-code';
//import SimpleImage from "@editorjs/simple-image";




const ContentEditor = ({article_block, onchange_callback, read_only=false}) => {
    const editor = useRef(null);

    const initEditor = ()=>{
        editor.current = new EditorJS({
            readOnly: read_only,
            holder: 'editorjs',

            tools: {
                

                linktool:LinkTool,

                header: {
                    class: Header,
                    inlineToolbar: ['marker', 'link'],
                    config: {
                        placeholder: 'Header'
                    },
                    shortcut: 'CMD+SHIFT+H'
                },

                
                image: {
                    class: ImageTool,
                    config: {
                        endpoints: {
                            byFile: 'https://marktestserver.pythonanywhere.com/public/upload-article/image/',
                            byUrl: 'https://marktestserver.pythonanywhere.com/public/upload-article/image/',
                        },
                        additionalRequestHeaders: {
                            'Authorization': `TOKEN ${sessionStorage.getItem("acctoken")}`,   
                        },
                    }
                },

                
                list: {
                    class: List,
                    inlineToolbar: true,
                    shortcut: 'CMD+SHIFT+L'
                },

                checklist: {
                    class: Checklist,
                    inlineToolbar: true,
                },

                quote: {
                    class: Quote,
                    inlineToolbar: true,
                    config: {
                        quotePlaceholder: 'Enter a quote',
                        captionPlaceholder: 'Quote\'s author',
                    },
                    shortcut: 'CMD+SHIFT+O'
                },

                warning: Warning,

                marker: {
                    class:  Marker,
                    shortcut: 'CMD+SHIFT+M'
                },

                code: {
                    class:  CodeTool,
                    shortcut: 'CMD+SHIFT+C'
                },

                delimiter: Delimiter,

                inlineCode: {
                    class: InlineCode,
                    shortcut: 'CMD+SHIFT+C'
                },


                table: {
                    class: Table,
                    inlineToolbar: true,
                    shortcut: 'CMD+ALT+T'
                },

            },

            data: {
                blocks : article_block
            },

            onChange: async () => {
                if(editor.current && read_only === false){
                    let content = await editor.current.save();
                    onchange_callback(content);
                }
            }
        });
    }



    useEffect(()=>{
        if (editor.current === null){
            initEditor();

            if (editor.current && read_only === false) {
                editor.current.isReady
                .then(() => {
                editor.current.save().then((content) => onchange_callback(content));
                })
                .catch((e) => console.log('Editor.js initialization error', e));
            }
        }
    });

    return (
        <>
            <div id="editorjs" className="bgcolor-white"></div>
        </>
    );
}

export default ContentEditor;
