import './footer.css';
import React from 'react';
import { Link } from "react-router-dom";
import MainLogoDark from "../inpage_images/icons/main_logo_dark.svg";

const Footer = () => {
    return ( 
        <div className="footer bg-gray-100 mt-5 pb-5">
            <div className="container">
                <div className='row'>
                    <div className="col-md-12 d-flex flex-column align-items-center justify-content-center mt-5 pt-5 px-2">
                        <div className='d-flex'>
                            <img src={ MainLogoDark } alt='my logo' className='pl-5' id="header_main_logo" />
                            <span className='className="fw-bolder text-3xl'>Lets Do Something Cool!</span>
                        </div>
                        <p className='text-sm mt-5'>
                            Do you have game ideas, Apps, websites or any other projects in mind? Do you need a companion to work with?. Feel free to contact me.
                        </p>

                        <Link to="/pages/contact" className="primary-button mt-5" aria-current="page">Contact Me</Link>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default Footer;