import './contact.css';
import React, { useState, useEffect } from 'react';
import { postRequest, getRequest } from '../../serverComponents/server_contact';
import MaintenancePage from '../subComponents/maintenance_page';
import { useToast } from '@chakra-ui/react'







const checkConnectionToServer = (connection_status)=>{
    if (!connection_status){
        return <MaintenancePage />
    }
}




const Contact = () => {
    const [contact_user_name, setContactUserName] = useState('');
    const [contact_user_email, setContactUserEmail] = useState('');
    const [contact_user_message, setContactUserMessage] = useState('');
    let [connection_stablished, setConnectionStablished] = useState(true);
    const toast = useToast();
    
    useEffect(()=>{
        window.scrollTo(0, 0);
        getRequest('/public/ping-api/')
        .then(()=>{
            setConnectionStablished(true);
        })
        .catch((error)=>{
            setConnectionStablished(false);
        })
    }, [])



    const toastResponse = (status, title, message)=>{
        toast({
            title: title,
            description: message,
            status: status,
            duration: 3000,
            isClosable: true,
          })
    }


    const sendMessage = (e) =>  {
        e.preventDefault();
        const data = {
          contact_user_name,
          contact_user_email,
          contact_user_message
        };
        postRequest('/public/contact/', data)
        .then(response=>{
            if(response.status === 201){
                toastResponse("success", "Message sent!", "Thanks for messaging me. Please wait for my response.");
            }
        }).catch(error=>{
            toastResponse("error", "Message sent failed!", `Error sending message: ${error.data.status}` );
        });
      };


    return ( 
        <div className="container page pt-5">
             
            <div className="row align-items-center">
                {checkConnectionToServer(connection_stablished)}
                {connection_stablished && <div className="col-md-12">
                    <div className='col-md-12 d-flex align-items-center justify-content-center mt-3'>
                        <div className='w-full sm:w-1/2 box-shadowed p-5'>
                            <p className="color-black text-3xl font-primary">Reach out to me</p>
                            
                            <form onSubmit={sendMessage} className='mt-4'>
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">How should I call you?</label>
                                    <input 
                                        type="name" 
                                        className="form-control" 
                                        id="name" 
                                        aria-describedby="emailHelp" 
                                        placeholder="You Are?"
                                        required
                                        onChange={(e) => setContactUserName(e.target.value)}
                                    />  
                                </div>

                                <div className="form-group" id="email_input_form">
                                    <label htmlFor="exampleInputEmail1">Email address</label>
                                    <input 
                                        type="email" 
                                        className="form-control" 
                                        id="exampleInputEmail1" 
                                        aria-describedby="emailHelp" 
                                        placeholder="Enter email"
                                        required
                                        onChange={(e) => setContactUserEmail(e.target.value)}
                                    />
                                    <small id="emailHelp" className="form-text text-muted">
                                        How should I reach out back to you?
                                    </small>
                                </div>

                                <div className="form-group" id="message_area">
                                    <label htmlFor="exampleFormControlTextarea1">What's up!</label>
                                    <textarea 
                                        className="form-control" 
                                        id="exampleFormControlTextarea1" 
                                        rows="3"
                                        required
                                        onChange={(e) => setContactUserMessage(e.target.value)}
                                    />
                                </div>

                                <button className="primary-button w-100 mt-3" type='submit'>Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
     );
}
 
export default Contact;