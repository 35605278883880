import './App.css';
import React from 'react';
import Home from './myComponents/homePage';
import { createBrowserRouter, Route, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import RootLayout from './myComponents/rootLayout';
import Contact from './myComponents/pages/contact';
import Gallery from './myComponents/pages/gallery';
//import AdminSpace from './myComponents/pages/admin_space';
import ArticleEditor from './myComponents/pages/article_editor';
import LoginPage from './myComponents/pages/login_page';
import ArticlePage from './myComponents/pages/article_page';
import Articles from './myComponents/pages/articles';
import AppsPage from './myComponents/pages/apps_page';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route path='/' element={<Home />} />
      <Route path="/pages/contact" element={<Contact />} />
      <Route path="/pages/gallery" element={<Gallery />} />
      <Route path="/pages/login" element={<LoginPage />} /> 

      <Route path="/pages/article_editor/" element={<ArticleEditor />} />
      <Route path="/pages/article_editor/:id" element={<ArticleEditor />} />
      <Route path='/pages/article/:id' element={<ArticlePage />} />
      <Route path='/pages/articles' element={<Articles />} />
      <Route path="/pages/apps" element={<AppsPage />} />
    </Route>
  )
)

function App() {
  return (
      <RouterProvider router={router}></RouterProvider>
  );
}

export default App;
